<template>
  <div class="ModalFlex">
    <template v-if="create">
      <div class="Popup_Inner_main">
        <div @click="close()" class="Popup_close_btn">
          <img
            @load="imgLoaded"
            class="imgHide"
            src="@/assets/media/vectors/close.svg"
            alt=""
          />
        </div>
        <div class="Popup_title">Adaugă Autorizație/Licență</div>
        <div class="vInputDate">
          <div class="form-group" :class="warns[0] ? 'WRNG' : ''">
            <b>Denumire autorizație/licență</b>
            <input
              v-focus
              type="text"
              v-model="inputs.authorizationName"
              class="vInput"
            />
          </div>
          <div class="form-group" :class="warns[1] ? 'WRNG' : ''">
            <b>Data expirării</b>
            <div>
              <date-picker
                v-model="inputs.expiryDate"
                valueType="YYYY-MM-DDTHH:mm:ssZ"
                :disabled-date="dateFeatureChecker"
              />
            </div>
          </div>
          <div class="form-group">
            <b>Comentariu</b>
            <textarea
              v-model.trim="inputs.comments"
              class="vInput"
              width="100%"
              cols=""
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="Popup_footer">
        <div class="Popup_footer_col">
          <button @click="close" class="Popup_footer_btn Footer_btn1">
            Inapoi
          </button>
        </div>
        <div class="Popup_footer_col">
          <button @click="save" class="Popup_footer_btn Footer_btn2">
            Adauga
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="Popup_Inner_main">
        <div @click="close()" class="Popup_close_btn">
          <img
            @load="imgLoaded"
            class="imgHide"
            src="@/assets/media/vectors/close.svg"
            alt=""
          />
        </div>
        <div class="Popup_title">Vezi Autorizații/Licențe</div>
        <div v-if="data" class="TBParent">
          <table>
            <thead>
              <th>Autorizație/Licență</th>
              <th style="text-align: center">Data creării</th>
              <th style="text-align: center">Data expirării</th>
              <th style="text-align: center">Expiră în</th>
              <th style="text-align: right">Acțiuni</th>
            </thead>
            <tbody>
              <template v-for="(e, i) in data">
                <tr :key="e._Id">
                  <td>{{ e.authorizationName }}</td>
                  <td style="text-align: center">{{ e.__createdAt }}</td>
                  <td style="text-align: center">{{ e.__expiryDate }}</td>
                  <td style="text-align: center">
                    <span
                      v-if="expirations[i] !== undefined"
                      v-html="
                        { false: '-', true: '<i>Expirat</i>' }[
                          expirations[i]
                        ] || expirations[i]
                      "
                    ></span>
                    <i v-else class="fas fa-sync fa-spin"></i>
                  </td>
                  <td style="text-align: right">
                    <a
                      href="#"
                      @click.prevent="
                        showDetailsIndex = showDetailsIndex === i ? -1 : i
                      "
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: #39f;
                      "
                      ><i
                        :class="`fas fa-${
                          showDetailsIndex === i ? 'minus' : 'plus'
                        }-circle`"
                        style="line-height: 0; margin-right: 0.25rem"
                      ></i>
                      {{ showDetailsIndex === i ? "Ascunde" : "Comentarii" }}</a
                    >
                    |
                    <a
                      href="#"
                      @click.prevent="deleteAuth(e)"
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: red;
                      "
                      ><i
                        class="fas fa-times-circle"
                        style="line-height: 0; margin-right: 0.25rem"
                      ></i>
                      Șterge</a
                    >
                  </td>
                </tr>
                <tr
                  :key="`COMMENT_${e._Id}`"
                  v-if="showDetailsIndex === i"
                  class="TBGray"
                >
                  <td colspan="5">
                    <pre v-if="e.comments" style="max-height: 20rem">{{
                      e.comments
                    }}</pre>
                    <h4
                      v-else
                      class="prettyH4"
                      style="margin-bottom: 1rem; margin-top: 0"
                    >
                      <i class="fas fa-times"></i>
                      Nu sunt comentarii
                    </h4>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <h4 v-else class="prettyH4" style="color: #888">
          <i class="fas fa-times"></i>
          Nu sunt autorizații
        </h4>
      </div>
      <div class="Popup_footer">
        <div class="Popup_footer_col">
          <button @click="close" class="Popup_footer_btn Footer_btn1">
            Inapoi
          </button>
        </div>
        <div class="Popup_footer_col"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { PROJECTS_AUTH } from "@/api.js";
import DatePicker from "@/mixins/DatePicker.js";

export default {
  mixins: [DatePicker],
  props: {
    authData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    itemId() {
      return this.authData.id ?? null;
    },
    create() {
      return !!this.authData.__CREATE__;
    },
    data() {
      return Array.isArray(this.authData.authorizations) &&
        this.authData.authorizations.length
        ? this.authData.authorizations.reverse().map((e) => ({
            ...e,
            __createdAt: this.toDate(e.createdAt),
            __expiryDate: this.toDate(e.expiryDate),
            _Id: e.id ?? this.makeid(5),
          }))
        : null;
    },
    invalidForm() {
      return this.warns[0] || this.warns[1];
    },
  },
  data() {
    return {
      inputs: {
        authorizationName: "",
        expiryDate: null,
        comments: "",
      },
      warns: [false, false],
      expirations: [],
      expirationInterval: null,
      showDetailsIndex: -1,
    };
  },
  methods: {
    close(x) {
      this.$emit("Close", !!x);
    },
    setWarn(index, val) {
      this.$set(this.warns, index, !!val);
    },
    save() {
      const x = this.inputs;

      if (!x.authorizationName || !String(x.authorizationName).trim()) {
        this.setWarn(0, true);
      }
      if (!x.expiryDate || !String(x.expiryDate).trim()) {
        this.setWarn(1, true);
      }

      this.send();
    },
    send() {
      if (!Number.isInteger(this.itemId) || this.invalidForm) return;
      const { authorizationName, expiryDate, comments } = this.inputs;

      this.setSafeLoad();
      const err = (msg) => {
        this.$toastr.e(msg || "Crearea autorizației a eșuat.");
        this.setLoad();
        this.close(true);
      };

      PROJECTS_AUTH.add(
        Object.assign(
          {
            authorizationName,
            expiryDate,
            projectId: this.itemId,
          },
          comments ? { comments } : {}
        )
      )
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Autorizația a fost creată cu succes.");

            this.close(true);
            this.setLoad();
          } else {
            err();
          }
        })
        .catch(err);
    },
    deleteAuth(e) {
      if (!Number.isInteger(e?.id)) {
        this.$toastr.e("Error #5531");
        return;
      }

      this.setSafeLoad();
      const err = (msg) => {
        this.$toastr.e(msg || "Ștergerea autorizației a eșuat.");
        this.close(true);
        this.setLoad();
      };

      PROJECTS_AUTH.delete(e.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Autorizația a fost ștearsă cu succes.");

            this.close(true);
            this.setLoad();
          } else {
            err();
          }
        })
        .catch(err);
    },
    syncExpires() {
      clearInterval(this.expirationInterval);
      if (!this.data) return;
      const startAfter = 1000 - new Date().getMilliseconds() || 1000;

      this.expirationInterval = setTimeout(() => {
        const divides = [
          [1000, "milisecundă", "milisecunde"],
          [60, "secundă", "secunde"],
          [60, "minută", "minute"],
          [24, "oră", "ore"],
          [365, "zi", "zile"],
        ];
        const func = () => {
          this.data.forEach((e, i) => {
            const set = (x) => this.$set(this.expirations, i, x);

            const curr = new Date();
            const to = new Date(e.expiryDate);

            const diff = to - curr;

            if (diff < 1) {
              set(true);
              return;
            }

            if (isNaN(diff)) return;

            let prepare = diff;
            let finalPrepare = false;
            let currIndex = 0;

            for (const r of divides) {
              prepare = prepare / parseInt(r[0]);

              if (isNaN(prepare)) {
                set(false);
                return;
              }

              if (prepare < 1 || currIndex == divides.length - 1) {
                if (isNaN(finalPrepare[3])) {
                  set(true);
                  return;
                }
                const q = Math.floor(finalPrepare[3]);
                set(`${q} ${r[q == 1 ? 1 : 2]}`);
                return;
              }
              finalPrepare = [...r];
              finalPrepare[3] = prepare;
              currIndex++;
            }

            set(false);
          });
        };
        func();
        //sthis.expirationInterval = setInterval(func, 1000);
      }, startAfter);
    },
  },
  beforeDestroy() {
    clearInterval(this.expirationInterval);
  },
  created() {
    // PROJECTS_AUTH.get()
    if (this.create) {
      this.$watch("inputs.authorizationName", () => this.setWarn(0));
      this.$watch("inputs.expiryDate", () => this.setWarn(1));
    } else {
      this.$nextTick(this.syncExpires);
    }
  },
};
</script>

<style>
</style>