<template>
  <div class="ModalFlex">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img @load="imgLoaded" class="imgHide" src="@/assets/media/vectors/close.svg" alt="">
      </div>
      <div class="Popup_title">
        {{existent ? 'Modifică' : 'Adaugă'}} Proiect
      </div>
      <div class="vInputDate">
        <div class="form-header">
          Informații Generale Proiect
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[0]">
            <b>Titlu proiect</b>
            <input type="text" class="vInput" v-model="D.projectTitle">
          </div>
          <div class="form-group" :class="warns[13]">
            <b>Sursă de finanțare</b>
            <v-select 
              :options="financing.data"
              :loading="!financing.data.length && !financing.empty"
              label="sourceName"
              v-model="financing.value"
            />
          </div>
          <div class="form-group" :class="warns[7]">
            <b>Cod SMIS</b>
            <input type="text" class="vInput" v-model="D.smisCode">
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[3]">
            <b>Data început</b>
            <div>
              <date-picker 
                v-model="D.startDate"
                valueType="YYYY-MM-DDTHH:mm:ssZ"
                :disabled-date="x => D.finishDate && x > prepareValidatorDate(D.finishDate)"
              />
            </div>
          </div>
          <div class="form-group" :class="warns[4]">
            <b>Data estimată de finalizare</b>
            <div>
              <date-picker 
                v-model="D.finishDate"
                valueType="YYYY-MM-DDTHH:mm:ssZ"
                :disabled-date="x => x < prepareValidatorDate(D.startDate)"
              />
            </div>
          </div>
          <files-manager 
            v-model="files.list"
            :signDeleted="true"
            :classes="warns[14]"
          />
        </div>
        <div class="form-header">
          Informații Contract
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[1]">
            <b>Număr contract finanțare</b>
            <input type="text" class="vInput" v-model="D.numarContractFinantare">
          </div>
          <div class="form-group" :class="warns[2]">
            <b>Data contract finanțare</b>
            <div>
              <date-picker 
                v-model="D.dataContractFinantare"
                valueType="YYYY-MM-DDTHH:mm:ssZ"
              />
            </div>
          </div>
        </div>
        <div class="form-header">
          Date financiare
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[6]">
            <b>Valoarea proiect</b>
            <inp-number v-model="D.valoareaProiect" />
          </div>
          <div class="form-group" :class="warns[8]">
            <b>Procent cofinantare</b>
            <inp-number v-model="D.procentCofinantare" min="0" max="100" />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[10]">
            <b>Valoarea aditionala(neeligibila) fara TVA</b>
            <inp-number v-model="D.valoareaFaraTva" />
          </div>
          <div class="form-group" :class="warns[11]">
            <b>cota TVA</b>
            <inp-number v-model="D.cotaTva" min="0" max="100" />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[6]">
            <b>Valoare cofinanțată</b>
            <input type="text" class="vInput form-disabled" disabled="disabled" :value="autocalc.valoareaCofinantata">
          </div>
          <div class="form-group" :class="warns[8]">
            <b>Valoarea finanțată</b>
            <input type="text" class="vInput form-disabled" disabled="disabled" :value="autocalc.valoareaFinantata">
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[10]">
            <b>Valoare adițională totală</b>
            <input type="text" class="vInput form-disabled" disabled="disabled" :value="autocalc.valoareaAditionalaTotala">
          </div>
          <div class="form-group" :class="warns[11]">
            <b>Valoare totală proiect</b>
            <input type="text" class="vInput form-disabled" disabled="disabled" :value="autocalc.valoareaTotalaProiect">
          </div>
        </div>
        
      </div>
    </div>
    <div class="Popup_footer">
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          Inapoi
        </button>
      </div>
      <div class="Popup_footer_col">
        <button @click="save" class="Popup_footer_btn Footer_btn2">
          Salveaza
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PROJECTS, FINANCING } from '@/api.js'
import DatePicker from '@/mixins/DatePicker.js'
import FilesAgent from '@/components/FilesAgent'

const prepareNumberMap = [
  'valoareaProiect',
  'procentCofinantare',
  'valoareaFaraTva',
  'cotaTva'
]

export default {
  mixins: [DatePicker],
  components: {
    'files-manager': FilesAgent
  },
  props: {
    Data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    const data = {
      D: this.Data,
      inputsWarnings: {},
      existent: Number.isInteger(this.Data.__existent__),
      modifyId: this.Data.__existent__ ?? null,
      attachedDocuments: this.Data.__docs__ ?? null,
      existentData: {},
      financing: {
        empty: false,
        data: [],
        value: null
      },
      files: {
        list: [],
        wrng: false
      }
    }

    delete this.Data.__existent__
    delete this.Data.__docs__

    return data
  },
  computed: {
    autocalc() {
      const x = this.getLocaleNumberBeautified
      const y = this.D
      const z = this.toPriceFormat
      
      const valoareaCofinantata = this._Multiply(x(y['valoareaProiect']), 
        this._Divide(x(y['procentCofinantare']), 100))
      const valoareaAditionalaTotala = z(this._Sum(this._Multiply(this._Divide(x(y['cotaTva']),100),x(y['valoareaFaraTva'])), x(y['valoareaFaraTva'])))
      
      return {
        valoareaCofinantata: z(valoareaCofinantata),
        valoareaFinantata: z(this._Minus(x(y['valoareaProiect']), valoareaCofinantata)),
        valoareaAditionalaTotala,
        valoareaTotalaProiect: z(this._Sum(x(y['valoareaProiect']), x(valoareaAditionalaTotala)))
      }
    },
    lastData() {
      return Object.assign({}, this.D)
    },
    warns() {
      const x = this.inputsWarnings
      const y = key => x[key] ? 'WRNG' : ''
      return {
        0: y('projectTitle'),
        1: y('numarContractFinantare'),
        2: y('dataContractFinantare'),
        3: y('startDate'),
        4: y('finishDate'),
        6: y('valoareaProiect'),
        7: y('smisCode'),
        8: y('procentCofinantare'),
        10: y('valoareaFaraTva'),
        11: y('cotaTva'),
        13: y('financing'),
        14: this.files.wrng ? 'WRNG' : ''
      }
    }
  },
  watch: {
    lastData: {
      handler(val, old) {
        Object.entries(val).forEach(e => {
          if(e[1] !== old[e[0]]) {
            this.$set(this.inputsWarnings, e[0], false)
          }
        })
      },
      deep: true
    }
  },
  methods: {
    close(x) {
      this.$emit('Close', x === true)
    },
    prepareValidatorDate(x) {
      if(!x) return ''
      
      return new Date(x)
    },
    save() {
      let succes = true;
      if(!this.financing.data.length) {
        this.$toastr.w('Sursele de finanțare nu au fost încărcate!', 'Atentie')
        succes = false
      }

      const prepared = {}

      Object.entries(this.D).forEach(e => {
        if(prepareNumberMap.includes(e[0])) {
          prepared[e[0]] = this.getLocaleNumber(e[1])
        }
        if(!e[1] || !String(e[1]).trim()) {
          this.$set(this.inputsWarnings, e[0], true)
          succes = false
        }
      })
      if(!this.files.list.filter(e => !e.deleted).length) {
        if(succes) this.$toastr.w('Este nevoie să atașați măcar un fișier!', 'Atentie!')
        this.files.wrng = true
        succes = false
      }

      if(succes) {
        this[this.existent ? 'editProject' : 'createProject'](prepared)
      }
    },
    editProject(prepared) {
      const id = this.modifyId
      const x = {...this.D, ...prepared}
      const y = this.existentData
      const prepare = {}

      const newFiles = this.files.list.filter(e => !e.__OLD_FILE__)
      const deletedOldFiles = this.files.list.filter(e => e.__OLD_FILE__ && e.deleted)

      let includeAutocals = false

      Object.entries(x).forEach(e => {
        if(e[1] != (e[0] == 'financing' ? (y[e[0]])?.id : y[e[0]])) {
          if(prepareNumberMap.includes(e[0])) includeAutocals = true
          prepare[e[0]] = e[1]
        }
      })

      if(deletedOldFiles.length) {
        prepare['attachedDocuments'] = this.attachedDocuments.filter(e => ! deletedOldFiles.find(i => i.key == e))
      }

      if(this.isObjEmpty(prepare) && !newFiles.length) {
        this.$toastr.w('Nu au fost găsite modificări.','Atentie!')
      } else {
        if(includeAutocals) {
          Object.entries(this.autocalc).forEach(e => {
            prepare[e[0]] = this.getLocaleNumber(e[1])
          })
        }

        const err = msg => this.$toastr.e(msg || 'Modificarea proiectului a eșuat.')

        const prepareData = new FormData()
        Object.entries(prepare).forEach(e => prepareData.append(e[0], e[1]))
        newFiles.forEach(e => prepareData.append('files', e.file))

        this.setSafeLoad()
        PROJECTS.edit(id, prepareData).then(res => {
          if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s('Proiectul a fost modificat cu succes.')
          } else {
            err()
          }

          this.close(true)
          this.setLoad(false)
        }).catch(msg => {
          this.close(true)
          this.setLoad(false)
          err(msg)
        })
      }
    },
    createProject(prepared) {
      const err = msg => this.$toastr.e(msg || 'Crearea proiectului a eșuat.')
      const x = {...this.D, ...prepared}
      const prepareData = new FormData()
      Object.entries(this.autocalc).forEach(e => prepareData.append(e[0], this.getLocaleNumber(e[1])))
      Object.entries(x).forEach(e => prepareData.append(e[0], e[1]))
      this.files.list.forEach(e => prepareData.append('files', e.file))

      this.setSafeLoad()
      PROJECTS.add(prepareData).then(res => {
        if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
          this.$toastr.s('Proiectul a fost creat cu succes.')
        } else {
          err()
        }

        this.close(true)
        this.setLoad(false)
      }).catch(msg => {
        this.close(true)
        this.setLoad(false)
        err(msg)
      })
    },
    getFinancing() {
      const err = msg => {
        this.$toastr.e(msg || 'Obținerea surselor de finanțare a eșuat.')
        this.financing.empty = true
      }

      FINANCING.get(1, 500).then(res => {
        if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode) && Array.isArray(res.data?.result)) {
          if(res.data.result.length) {
            this.financing.data = res.data.result

            if(this.existent) {
              const financingId = this.D?.financing?.id
              const find = this.financing.data.find(e => e.id == financingId)
              if(find) {
                this.financing.value = find
              }
            }
          } else {
            this.financing.empty = true
          }
        } else {
          err()
        }
      }).catch(msg => {
        err(msg)
      })
    }
  },
  created() {
    this.getFinancing()
    
    if(this.existent) {
      prepareNumberMap.forEach(e => {
        const x = parseFloat(this.Data[e])
        this.Data[e] = isNaN(x) ? '' : String(x)
      })
      this.existentData = {...this.Data}
    }

    if(Array.isArray(this.attachedDocuments)) {
      const prepare = this.attachedDocuments.map(e => ({
        file: {
          name: this.getNeedFileName(e)
        },
        key: e,
        deleted: false,
        __OLD_FILE__: true
      }))

      this.files.list.unshift(...prepare)
    }

    this.$watch('financing.value', {
      handler() {
        this.$set(this.inputsWarnings, 'financing', false)

        this.D['financing'] = this.financing?.value?.id ?? ''
      },
      deep: true
    })
    this.$watch('files.list', {
      handler() {
        this.files.wrng = false
      },
      deep: true
    })
  }
}
</script>