<template>
  <div style="padding-left: 1.75rem">
    <clasic-modal v-model="modalOpen" :disabledBg="true" extendedMode="1">
      <Input :Data="inputsData" @Close="(x) => setModal(false, x)" />
    </clasic-modal>
    <clasic-modal
      v-model="projectsAuth.modal"
      :extendedMode="
        projectsAuth.auth && projectsAuth.auth.__CREATE__ ? '' : '1'
      "
    >
      <Auth
        :authData="projectsAuth.auth"
        @Close="
          (x) => {
            projectsAuth.modal = false;
            if (x) reloadPage();
          }
        "
      />
    </clasic-modal>
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      <span v-html="$route.name"></span>
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" style="margin-top: 1.2rem">
        <div v-if="PERMISIONS.create" class="right">
          <div @click="setModal(true)" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="@/assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content"> Adaugă proiect </span>
          </div>
        </div>
      </div>
    </div>
    <table-parent
      v-if="PERMISIONS.list"
      :reloadCount="reloadTable"
      :tableHead="tableHead"
      :prepareFn="prepareData"
      :apiModule="tableModule"
      :apiModuleName="'PROJECTS'"
      :disableSubData="true"
      @Btn1="viewExpenses"
      @Btn2="editProject"
      @Btn3="deleteProject"
      @Btn7="viewFiles"
      @Btn18="viewAuthorizations"
      @Btn19="(x) => viewAuthorizations(x, true)"
      @totalRecords="x => (totalRecords = x)"
    />
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import { PROJECTS } from "@/api.js";
import { mapMutations } from "vuex";
import PERMISIONS from "@/mixins/projectsPermMixin.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
import Input from "@/components/ProjectsInput";
import Auth from "@/components/ProjectsAuthorizations";

const inputsData = () => ({
  projectTitle: "",
  numarContractFinantare: "",
  dataContractFinantare: "",
  smisCode: null,
  startDate: "",
  finishDate: "",
  financing: "",
  valoareaProiect: "",
  procentCofinantare: "",
  valoareaFaraTva: "",
  cotaTva: "",
});

export default {
  mixins: [PERMISIONS],
  name: "Proiecte",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    TableParent,
    Input,
    Auth,
  },
  data() {
    return {
      inputsData: inputsData(),
      reloadTable: 0,
      modalOpen: false,
      projectsAuth: {
        modal: false,
        data: null,
      },
      tableHead: [
        {
          title: "Titlu proiect",
          sort: true,
          queryKey: "projectTitle",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data creării",
          sort: true,
          queryKey: "createdAt",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          title: "NR. Contract finantare",
          sort: true,
          queryKey: "numarContractFinantare",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Finantare",
          sort: true,
          metaType: "FINANCING",
          queryKey: "financing.sourceName",
          bottomHead: {
            quikFiltre: true,
            multiselect: true,
          },
        },
        {
          title: "Data contract finantare",
          sort: true,
          queryKey: "dataContractFinantare",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          title: "Cod SMIS",
          sort: true,
          sort: true,
          queryKey: "smisCode",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Data inceput",
          sort: true,
          queryKey: "startDate",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          title: "Data finalizare",
          sort: true,
          queryKey: "finishDate",
          bottomHead: {
            quikFiltre: "date",
            range: true,
          },
        },
        {
          title: "Autorizatie constructie nr. zile expirare",
          sort: true,
          queryKey: "autorizatieConstructieDataExpirarii",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Stadiul Financiar",
          sort: true,
          queryKey: "financialStage",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Ramas de plata",
          sort: true,
          queryKey: "",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Stadiul fizic",
          sort: true,
          queryKey: "fizicStage",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          spacer: true,
          minimize: 1,
          fixed: {
            right: true,
          },
          bottomHead: {
            quikFiltre: "clear",
          },
        },
      ],
      tableModule: PROJECTS.get,
      totalRecords: 0,
    };
  },
  watch: {
    modalOpen(x) {
      if (!x) {
        this.inputsData = inputsData();
      }
    },
  },
  methods: {
    ...mapMutations(["addWindow"]),
    viewAuthorizations(item, create) {
      if(!Number.isInteger(item?.id)) return

      this.$set(this.projectsAuth, "auth", {
        ...item,
        __CREATE__: !!create,
      });
      this.projectsAuth.modal = true;
    },
    viewExpenses(item) {
      if(!Number.isInteger(item?.id)) return

      this.setSafeLoad()
      this.$router.push(`/${this.$route.params.navigation}/proiecte/${item.id}`);
    },
    viewFiles(item) {
      if(!Number.isInteger(item?.id)) return

      this.addWindow({
        fileList: item.attachedDocuments || [],
        select: item.attachedDocuments?.length ? 0 : -1,
        itemId: item.id,
        searchModule: "PROJECTS",
        canDelete: false,
        modal: 13,
      });
    },
    editProject(item) {
      if(!Number.isInteger(item?.id)) return

      const x = Object.keys(this.inputsData);
      const prepare = {};
      x.forEach((e) => {
        prepare[e] = item[e] || "";
      });

      this.inputsData = {
        ...prepare,
        __docs__: item.attachedDocuments || null,
        __existent__: item.id,
      };
      this.modalOpen = true;
    },
    deleteProject(item) {
      if(!Number.isInteger(item?.id)) return

      this.$modal.show(
        DialogModal,
        {
          target: "ProjectDelete",
          title: "Ștergere proiect",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi proiectul selectat?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Sterge",
            handler: () => {
              const err = (msg) =>
                this.$toastr.e(
                  msg || "Ștergerea proiectului a eșuat."
                );

              this.setSafeLoad();
              PROJECTS.delete(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s(
                      "Proiectul a fost șters cu succes."
                    );
                  } else {
                    err();
                  }
                  this.reloadPage();
                  this.setLoad();
                })
                .catch((msg) => {
                  err(msg);
                  this.setLoad();
                  this.reloadPage();
                });
            },
          },
        },
        {
          name: "ProjectDelete",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    reloadPage() {
      this.reloadTable++
      this.totalRecords = 0;
    },
    setModal(x, reload) {
      this.modalOpen = !!x;
      if (!x) {
        this.inputsData = inputsData();
      }
      if (reload) this.reloadPage();
    },
    prepareData(r) {
      const sortedAuth = Array.isArray(r.authorizations)
        ? this.toDate(
            r.authorizations.sort(
              (a, b) => new Date(a.expiryDate) > new Date(b.expiryDate)
            )[0]
          ) ?? '-'
        : "";

      const preparation = [
        r.projectTitle || "-",
        this.toDateAndTime(r.createdAt) || "-",
        r.numarContractFinantare || "-",
        r.financing?.sourceName || "-",
        this.toDate(r.dataContractFinantare) || "-",
        r.smisCode || "-",
        this.toDate(r.startDate) || "-",
        this.toDate(r.finishDate) || "-",
        sortedAuth,
        r.financialStage ? `${this.calcPercent(r.financialStage)}%` : "0,00",
        r.ramasDePlata
          ? this.toPriceFormat(Math.abs(r.ramasDePlata))
          : "0,00",
        { "-": "0,00" }[r.fizicStage] || r.fizicStage || "0,00",
      ];

      const uuid = r.createdByUser;
      const x = this.PERMISIONS;
      const prepareBtn = [
        [
          18,
          "Vezi autorizații/licențe",
          ["fas fa-list", "font-size:2.2rem;color:#3c763d;"],
        ],
        [
          19,
          "Adaugă autorizație/licență",
          ["fas fa-marker", "font-size:2.2rem;color:#3c763d;"],
        ],
        [7, "Vezi fișiere"],
      ];

      const checkOwner = o => {
        if(!o) return false
        if(o === true) return true
        if(o == uuid) {
          return true
        }
        if(Array.isArray(o) && o[1] == uuid) {
          return true
        }
        return false
      }

      if (checkOwner(x.edit)) {
        prepareBtn.push(2);
      }
      if (checkOwner(x.delete)) {
        prepareBtn.push(3);
      }
      prepareBtn.push([1, "Cheltuieli"]);
      preparation.push([prepareBtn]);

      return preparation
    },
  },
};
</script>